<script>
  import { Pie } from 'vue-chartjs'

  export default {
    mixins: [Pie],
    props: {
      chartdata: {
        type: Object,
        default: null,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    mounted () {
      this.renderChart(this.chartdata, this.options)
    },
  }
</script>

<style></style>
